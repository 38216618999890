import * as React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Typography from "@mui/material/Typography";
import LazyLoad from "react-lazy-load";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { getCatalogoLocalComercialById } from "../services/LocalComercial.js"; // Importar la función getInmueble
import "../css/Comercial.css";
import { Link } from "react-router-dom";

const ComponenteComercial = ({ comercialId }) => {
  // Pasar el ID del inmueble como prop

  const [comercialData, setComercialData] = React.useState(null);

  React.useEffect(() => {
    // Cargar los detalles del inmueble al montar el componente
    async function fetchComercialData() {
      try {
        const response = await getCatalogoLocalComercialById(comercialId);
        setComercialData(response.data[0]);
        // console.log(comercialId);
      } catch (error) {
        console.log(error);
        // Puedes mostrar un mensaje al usuario indicando que ocurrió un error en la solicitud
        // O realizar cualquier otra acción de manejo de errores que consideres adecuada.
      }
    }
    fetchComercialData();
  }, [comercialId]);

  return (
    <>
      {comercialData && ( // Mostrar el contenido una vez que se hayan obtenido los datos
        <div className="catalogue-container">
          <LazyLoad>
            <div className="contenedor-catalogo-kula-informacion">
              <div className="contenedor-kula">
                <iframe
                  title="kuula"
                  className="kula"
                  allow="xr-spatial-tracking; gyroscope; accelerometer"
                  allowFullScreen
                  src={comercialData.linkKuula} // Usar el link del inmueble desde la base de datos
                ></iframe>
              </div>

              <div className="contact-info">
                <Typography>
                  <strong className="catalog-info">Local:</strong>{" "}
                  {comercialData.nombreLocal}
                  <br />
                  <strong className="catalog-info">Tipo de Local:</strong>{" "}
                  {comercialData.nombreTipoLocalComercial}
                  <br />
                  <strong className="catalog-info">País:</strong>{" "}
                  {comercialData.nombrePais}
                  <br />
                  <strong className="catalog-info">Ciudad:</strong>{" "}
                  {comercialData.nombreCiudad}
                  <br />
                </Typography>

                <Typography>
                  <strong className="catalog-info">Sector:</strong>{" "}
                  {comercialData.sector}
                  <br />
                  <strong className="catalog-info">Contacto:</strong>{" "}
                  {comercialData.contacto}
                  <br />
                  <a href={`${comercialData.whatsApp}`}>
                    <FaWhatsapp
                      style={{
                        fontSize: "27px",
                        marginRight: "2%",
                        color: "green",
                        marginTop: "2%",
                      }}
                    />
                  </a>
                  <a href={`${comercialData.facebook}`}>
                    <FaFacebook
                      style={{
                        fontSize: "27px",
                        color: "blue",
                        marginTop: "2%",
                      }}
                    />
                  </a>
                  <a href={`${comercialData.instagram}`}>
                    <FaInstagram
                      style={{
                        fontSize: "27px ",
                        marginLeft: "2%",
                        color: "#571b3c",
                        marginTop: "2%",
                      }}
                    />
                  </a>
                  <br />
                </Typography>
                <div className="contenedor-mas-informacion">
                <Link
                  to={`/Comercial/${comercialId}`}
                  className="mas-informacion-button"
                >
                  Más Información
                </Link>
              </div>
              </div>
              
            </div>
          </LazyLoad>
        </div>
      )}
      <h2 className="encabezado-con-linea"></h2>
    </>
  );
};

export default ComponenteComercial;
