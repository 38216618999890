import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { login } from "../services/authApi";
import "../css/LoginForm.css";
import Footer from "./Footer.js";
import "../css/normalize.css";

const LoginForm = () => {
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [contrasena, setContrasena] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    const exitoInicioSesion = await login(nombreUsuario, contrasena);
    if (exitoInicioSesion.userType === 1) {
      // Inicio de sesión exitoso, realizar las acciones necesarias (redirección, etc.)
      alert("Bienvenido al Administrador " + nombreUsuario);
      window.location.href = "/Inmueble";
    } else {
      // Error de inicio de sesión, manejarlo adecuadamente (mostrar mensaje de error, etc.)
      alert("Error de inicio de sesión, intentelo de nuevo");
      window.location.reload();
    }
  };

  return (
    <>
      <div className="custom-container div">
        <div className="custom-box box">
          <Typography
            variant="h4"
            component="h1"
            className="custom-heading heading"
            style={{ color:"#571b3c"}}
          >
            INPRO 360
          </Typography>
          <Typography
            variant="subtitle1"
            className="custom-subheading subheading"
          >
            Iniciar sesión
          </Typography>
          <form onSubmit={handleLogin}>
            <TextField
              label="Usuario"
              variant="outlined"
              className="custom-textfield textfield"
              value={nombreUsuario}
              onChange={(e) => setNombreUsuario(e.target.value)}
              fullWidth
            />
            <TextField
              label="Contraseña"
              type="password"
              variant="outlined"
              className="custom-textfield textfield"
              value={contrasena}
              onChange={(e) => setContrasena(e.target.value)}
              fullWidth
            />
            <Button
              type="submit"
              variant="contained"
              className="custom-button button"
            >
              Ingresar
            </Button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoginForm;
