import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { getLocalComercialById } from "../services/LocalComercial";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import "../css/ComercialIndividual.css";

const Comercial = () => {
  const { id } = useParams(); // Obtener el ID del comercial de la URL
  const [comercialData, setComercialData] = useState(null);

  useEffect(() => {
    async function fetchComercialData() {
      try {
        const response = await getLocalComercialById(id);
        setComercialData(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    }
    fetchComercialData();
  }, [id]);

  if (!comercialData) {
    return null;
  }

  return (
    <>
      <Header />
      <div className="comercial-details">
        <h1 className="textos-titulo1">
          {comercialData.nombreLocal}
          {" - "} {comercialData.nombreTipoLocalComercial}
        </h1>

        <h2 className="textos-titulo2">
          {comercialData.nombrePais}
          {" - "}
          {comercialData.nombreCiudad} {" - "}
          {comercialData.sector}
        </h2>

        <div className="contenedor-kula-iframe">
          <iframe
            title="Kulua"
            className="kula-iframe-comercial"
            allow="xr-spatial-tracking; gyroscope; accelerometer"
            allowFullScreen
            loading="lazy"
            src={comercialData.linkKuula}
          ></iframe>
        </div>

        <div className="contenedor-descripcion-google">
          <p className="texto-descripcion">{comercialData.descripcion}</p>
          <iframe
            title="GoogleMaps"
            className="google-maps-iframe"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            src={comercialData.linkGoogleMaps}
          ></iframe>
        </div>

        <div className="contenedor-redes-sociales">
          <div className="iconos-redes">
            <a href={`${comercialData.whatsApp}`}>
              <FaWhatsapp
                className="iconos-redes-sociales"
                style={{
                  color: "green",
                }}
              />
            </a>

            <a href={`${comercialData.facebook}`}>
              <FaFacebook
                className="iconos-redes-sociales"
                style={{
                  color: "blue",
                }}
              />
            </a>

            <a href={`${comercialData.instagram}`}>
              <FaInstagram
                className="iconos-redes-sociales"
                style={{
                  color: "#571b3c",
                }}
              />
            </a>
          </div>

          <a href={`${comercialData.whatsApp}`} className="texto-contacto">
            {comercialData.contacto}
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Comercial;
