import React, { useState, useEffect } from "react";
import ComponenteCatalogo from "./ComponenteCatalogo.js";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { getInmueblesCatalogo } from "../services/FormularioInmuebles.js";
import { Typography, TextField, Button, Menu, MenuItem } from "@mui/material";
import giphyGif from "../pictures/giphy.gif";
import "../css/CatalogoView.css";
import "../css/normalize.css";

const CatalogoView = () => {
  const [inmueblesList, setInmueblesList] = useState([]);
  const [filteredInmuebles, setFilteredInmuebles] = useState([]);
  const [filters, setFilters] = useState({
    minPrecio: "",
    maxPrecio: "",
    tipoInmueble: "",
    ciudad: "",
    plantas: "",
    habitaciones: "",
    banos: "",
  });

  useEffect(() => {
    async function fetchInmueblesData() {
      const response = await getInmueblesCatalogo();
      setInmueblesList(response.data);
    }
    fetchInmueblesData();
  }, []);

  useEffect(() => {
    const filtered = inmueblesList.filter((inmueble) => {
      const precio = parseFloat(inmueble.precio);
      return (
        (!filters.minPrecio || precio >= parseFloat(filters.minPrecio)) &&
        (!filters.maxPrecio || precio <= parseFloat(filters.maxPrecio)) &&
        (!filters.tipoInmueble ||
          inmueble.nombreTipoInmueble
            .toLowerCase()
            .includes(filters.tipoInmueble.toLowerCase())) &&
        (!filters.ciudad ||
          inmueble.ciudad
            .toLowerCase()
            .includes(filters.ciudad.toLowerCase())) &&
        (!filters.plantas ||
          parseInt(inmueble.plantas) === parseInt(filters.plantas)) &&
        (!filters.habitaciones ||
          parseInt(inmueble.habitaciones) === parseInt(filters.habitaciones)) &&
        (!filters.banos || parseInt(inmueble.banos) === parseInt(filters.banos))
      );
    });

    setFilteredInmuebles(filtered);
  }, [inmueblesList, filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if (
      name === "minPrecio" ||
      name === "maxPrecio" ||
      name === "plantas" ||
      name === "habitaciones" ||
      name === "banos"
    ) {
      if (!isNaN(value)) {
        setFilters({ ...filters, [name]: value });
      }
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  const handleFilterReset = () => {
    setFilters({
      minPrecio: "",
      maxPrecio: "",
      tipoInmueble: "",
      ciudad: "",
      plantas: "",
      habitaciones: "",
      banos: "",
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Desplazamiento suave
    });
  };

  const [isLoading, setIsLoading] = useState(true); // Estado para controlar la carga

  useEffect(() => {
    async function fetchInmueblesData() {
      const response = await getInmueblesCatalogo();
      setInmueblesList(response.data);

      // Agregar un retraso de 1 segundo antes de cambiar isLoading a false
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    fetchInmueblesData();
  }, []);

  return (
    <>
      <Header />
      {/* Filtros de búsqueda */}
      <div className="componente-filtros">
        {/* Primera fila de filtros */}
        <div className="filtros-row">
          {/* Filtros de Tipo Inmueble */}
          <TextField
            label="Tipo de inmueble"
            variant="outlined"
            name="tipoInmueble"
            value={filters.tipoInmueble}
            onChange={handleFilterChange}
          />
          {/* Filtros de Ciudad */}
          <TextField
            label="Ciudad"
            variant="outlined"
            name="ciudad"
            value={filters.ciudad}
            onChange={handleFilterChange}
          />
          {/* Filtros de Plantas */}
          <TextField
            label="Plantas"
            variant="outlined"
            name="plantas"
            value={filters.plantas}
            onChange={handleFilterChange}
            type="text"
            inputProps={{ pattern: "[0-9]*" }}
          />
        </div>
        {/* Segunda fila de filtros */}
        <div className="filtros-row2">
          {/* Filtros de Habitaciones */}
          <TextField
            label="Habitaciones"
            variant="outlined"
            name="habitaciones"
            value={filters.habitaciones}
            onChange={handleFilterChange}
            type="text"
            inputProps={{ pattern: "[0-9]*" }}
          />
          {/* Filtros de Precio */}
          <div className="filtro-precios">
            <Button
              variant="outlined"
              color="primary"
              onClick={openMenu}
              className="boton-Precio"
              style={{
                width: "100%",
                height: "58px",
                backgroundColor: "transparent",
                color: "grey",
                textTransform: "none", // Desactiva la transformación de texto
                fontSize: "81%",
                border: "1px solid rgba(0, 0, 0, 0.23)",
              }}
            >
              {filters.minPrecio && filters.maxPrecio
                ? `Precio: ${filters.minPrecio} - ${filters.maxPrecio}`
                : "Precio: Desde - Hasta"}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={closeMenu}
            >
              <MenuItem>
                {/* Contenedor para el botón y el texto */}
                <div className="modal-precio">
                  <TextField
                    label="Desde"
                    variant="outlined"
                    name="minPrecio"
                    value={filters.minPrecio}
                    onChange={handleFilterChange}
                    type="text"
                    inputProps={{ pattern: "[0-9]*" }}
                  />
                  <div className="separator">--</div>
                  <TextField
                    label="Hasta"
                    variant="outlined"
                    name="maxPrecio"
                    value={filters.maxPrecio}
                    onChange={handleFilterChange}
                    type="text"
                    inputProps={{ pattern: "[0-9]*" }}
                  />
                </div>
              </MenuItem>
            </Menu>
          </div>
          {/* Filtros de Precio */}
          {/* Botón Limpiar */}
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#571b3c",
              color: "white",
              height: "93%",
            }}
            onClick={handleFilterReset}
          >
            Limpiar
          </Button>
        </div>
      </div>
      {/* Mostrar el GIF mientras isLoading sea true */}
      {isLoading ? (
        <div className="loading-container">
          <div className="loading-gif">
            <img src={giphyGif} alt="Cargando..." />
          </div>
        </div>
      ) : (
        /* Resultados de búsqueda */
        <div>
          {filteredInmuebles.length > 0 ? (
            filteredInmuebles.map((inmueble) => (
              <ComponenteCatalogo key={inmueble.id} inmuebleId={inmueble.id} />
            ))
          ) : (
            <Typography variant="h6">
              No se han encontrado inmuebles que coincidan con los filtros.
            </Typography>
          )}
        </div>
      )}
      {/* Botón flotante para volver al inicio */}
      <div className="scroll-to-top">
        <Button
          variant="contained"
          color="primary"
          className="boton-volver-inicio"
          onClick={handleScrollToTop}
          style={{
            position: "fixed", // Agregamos posición fija
            right: "4%",
            bottom: "9%",
            borderRadius: "50%",
            width: "4%",
            height: "6%",
            backgroundColor: "#571b3c",
            color: "#fff",
            fontSize: "24px", // Aumentamos el tamaño de la letra
            lineHeight: "1", // Ajustamos el espaciado entre líneas
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ↑
        </Button>
      </div>

      <Footer />
    </>
  );
};

export default CatalogoView;
