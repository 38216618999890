import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { getInmueble } from "../services/FormularioInmuebles.js";
import { FaWhatsapp } from "react-icons/fa";
import Typography from "@mui/material/Typography";
import "../css/inmuebleIndividual.css";
import DEFAULT_IMAGE_URL from "../pictures/alt-de-una-imagen.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Inmueble = () => {
  const { id } = useParams();
  const [inmuebleData, setInmuebleData] = useState(null);

  useEffect(() => {
    async function fetchInmuebleData() {
      try {
        const response = await getInmueble(id);
        setInmuebleData(response.data[0]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchInmuebleData();
  }, [id]);

  if (!inmuebleData) {
    return null;
  }

  const renderSlides = () => {
    if (inmuebleData.imagenes && inmuebleData.imagenes.length > 0) {

      const imagenesArray = inmuebleData.imagenes.split(',');
      return imagenesArray.map((imagen, index) => (
        <div className="contenedor-imagenes"  key={index}>
          <img src={imagen} alt={`Imagen ${index}`} className="imagen-subida" />
        </div>
      ));
    } else {
      // Mostrar las imágenes por defecto
      return Array.from({ length: 3 }).map((_, index) => (
        <div className="contenedor-imagenes" key={index}>
          <img
            src={DEFAULT_IMAGE_URL}
            alt={`Imagen por defecto ${index}`}
            className="imagen-por-defecto"
          />
        </div>
      ));
    }
  };

  return (
    <>
      <Header />

      <h1 className="textos-titulo1">
        {inmuebleData.nombreProyecto}
        {" - "} {inmuebleData.nombreTipoInmueble}
      </h1>

      <h2 className="textos-titulo2">
        {inmuebleData.pais}
        {" - "}
        {inmuebleData.ciudad} {" - "}
        {inmuebleData.sector}
      </h2>

      <div className="contenedor-kula-imagenes">
        <div className="contenedor-kula-inmueble">
          <iframe
            title="Kulua"
            className="kula-iframe"
            allow="xr-spatial-tracking; gyroscope; accelerometer"
            allowFullScreen
            loading="lazy"
            src={inmuebleData.kuula}
          ></iframe>
        </div>
        <div className="contenedor-imagenes">
          <Carousel  >
            {renderSlides()}
          </Carousel>
        </div>
      </div>

      <div className="contenedor-datos-inmueble">
        <div className="contenedor-datos">
          <Typography className="tipo-letra">
            <strong>Habitaciones:</strong> {inmuebleData.habitaciones}
            <br />
            <strong>Metros Construcción:</strong>{" "}
            {inmuebleData.metrosConstruccion} m<sup>2</sup>
            <br />
          </Typography>
        </div>
        <div className="contenedor-datos">
          <Typography className="tipo-letra">
            <strong>Plantas:</strong> {inmuebleData.plantas}
            <br />
            <strong>Baños:</strong> {inmuebleData.banos}
            <br />
          </Typography>
        </div>
        <div className="contenedor-datos">
          <Typography className="tipo-letra">
            <strong>Metros Terreno:</strong> {inmuebleData.metrosTerreno} m
            <sup>2</sup>
            <br />
            <strong>Estacionamientos:</strong> {inmuebleData.estacionamientos}
            <br />
          </Typography>
        </div>
      </div>

      <div className="contenedor-contacto-google">
        <div className="contenedor-contactos">
          <h2>Para obtener información adicional, te invitamos a comunicarte con los siguientes contactos.</h2>
          <div className="contacto-whatsapp">
            <a href={`${inmuebleData.whatsapp}`}>
              <FaWhatsapp
                className="iconos-redes-sociales"
                style={{
                  marginRight: "10px",
                  color: "green",
                }}
              />
            </a>
            <a className="texto-contacto" href={`${inmuebleData.whatsapp}`}>
              {inmuebleData.contacto}
            </a>
          </div>
        </div>
        <div className="contenedor-google-inmueble">
          <iframe
            title="GoogleMaps"
            className="google-maps-iframe-inmueble"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            src={inmuebleData.googleMaps}
          ></iframe>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Inmueble;
