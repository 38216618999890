import Header from "./HeaderAdmin.js";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TableCatalogue from "./TableCatalogue.js";
import ModalInmueble from "./ModalInmueble.js";
import "../css/normalize.css";
import Cookies from "js-cookie";
import { isExpired } from "react-jwt";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "10%",
  left: "50%",
  transform: "translateX(-50%)",
  width: "70%",
  maxWidth: "70vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CRUDCatalogo = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const [token, setToken] = React.useState(Cookies.get("token"));
  const [redirected, setRedirected] = React.useState(false);

  const handleCloseModal = () => {
    handleClose(); // Cierra el modal
  };

  React.useEffect(() => {
    const tokenExpirationTime = 60 * 60 * 1000;
    const sessionWarningTime = 55 * 60 * 1000;
    const tokenTimer = setTimeout(() => {
      // Verifica si la cookie "token" ha dejado de existir
      if (!Cookies.get("token")) {
        // Si la cookie "token" no existe, redirige a /Admin
        console.log(
          "Cookie 'token' ha dejado de existir, redirigiendo a /Admin"
        );
        window.location.href = "/Admin";
      }
    }, tokenExpirationTime);

    const sessionWarningTimer = setTimeout(() => {
      // Muestra un alert al usuario a los 55 minutos para advertirle que la sesión se cerrará en 5 minutos
      alert(
        "Su sesión se cerrará automáticamente en 5 minutos. Guarde su trabajo."
      );
    }, sessionWarningTime);

    return () => {
      clearTimeout(tokenTimer); // Limpia el temporizador de token al desmontar el componente
      clearTimeout(sessionWarningTimer);
    }; // Limpia el temporizador al desmontar el componente
  }, []);

  return (
    <>
      <Header />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{
            width: "60%",
            marginTop: "3%",
            bgcolor: "#571b3c",
            color: "white",
            marginBottom: "2%",
            "&:hover": {
              bgcolor: "white", // Cambia el fondo a blanco en el hover
              color: "#571b3c", // Cambia el color del texto a negro en el hover
            },
          }}
        >
          Agregar Propiedad
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ModalInmueble handleCloseModal={handleCloseModal} />
          </Box>
        </Modal>
        <br />
        <TableCatalogue />
      </Box>
    </>
  );
};

export default CRUDCatalogo;
