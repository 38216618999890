import axios from "axios";
import Cookies from "js-cookie";
import { decodeToken } from "react-jwt";

const apiUrl = process.env.REACT_APP_API_URL;

export const login = async (nombreUsuario, contrasena) => {
  try {
    const response = await axios.post(apiUrl + "/api/login", {
      nombreUsuario,
      contrasena,
    });
    const token = response.data;

    // Almacena el token en la cookie
    Cookies.set("token", token, { expires: 1 / 24 });

    const decodedToken = decodeToken(token);
    const userType = decodedToken.userType;
    return { userType }; // Inicio de sesión exitoso
  } catch (error) {
    return false; // Error de inicio de sesión
  }
};

export const getUsuarios = async () => {
  try {
    const response = await axios.get(apiUrl + "/api/usuarios");

    // return console.log(response.data);
  } catch (error) {
    console.log(error);
  }
};
