import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Importa useLocation de react-router-dom
import "../css/Footer.css";
import "../css/normalize.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Footer = () => {
  const location = useLocation(); // Obtiene la ubicación actual
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    // Verificar si el contenido de la página es suficiente para activar el scroll
    const hasScrollBar = document.body.scrollHeight > window.innerHeight;

    // Verificar si la ubicación es "/catalogoView"
    const isCatalogoView = location.pathname === "/catalogoView";
    const isComercialView = location.pathname === "/comercialView";

    // Aplica las condiciones para establecer la posición y el ancho
    if (hasScrollBar || isCatalogoView || isComercialView) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
  }, [location]); // Agrega location como dependencia para que se actualice cuando cambie la ubicación

  return (
    <footer className={`footer ${showFooter ? "show-footer" : "hide-footer"}`}>
      <div className="footer-icons">
        <a href="https://www.facebook.com/profile.php?id=61550237374585&mibextid=LQQJ4d" className="footer-icon">
          <FacebookIcon />
        </a>
        <a href="https://instagram.com/inpro_360?igshid=MzRlODBiNWFlZA==" className="footer-icon">
          <InstagramIcon />
        </a>
        <a href="https://wa.link/jzk9pd" className="footer-icon">
          <WhatsAppIcon />
        </a>
      </div>
      <p className="footer-text">COPYRIGHT © 2023 INPRO 360</p>
    </footer>
  );
};

export default Footer;