import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import DescriptionIcon from "@mui/icons-material/Description";
import ContactsIcon from "@mui/icons-material/Contacts";
import logo from "../pictures/logo.png";
import "../css/Header.css";
import "../css/normalize.css";

const Header = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const location = useLocation();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const options = [
    { label: "INICIO", icon: <HomeIcon />, value: "/", path: "/" },
    {
      label: "QUIENES SOMOS",
      icon: <InfoIcon />,
      value: "/about",
      path: "/about",
    },
    {
      label: "COMPRA/VENTA",
      icon: <DescriptionIcon />,
      value: "/catalogoView",
      path: "/catalogoView",
    },
    {
      label: "LOCALES COMERCIALES",
      icon: <DescriptionIcon />,
      value: "/ComercialView",
      path: "/ComercialView",
    },
    {
      label: "CONTACTOS",
      icon: <ContactsIcon />,
      value: "/contact",
      path: "/contact",
    },
  ];

  return (
    <AppBar position="static" sx={{ background: "#571b3c" }}>
      <div id="menus" background="#571b3c">
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer}
              PaperProps={{
                sx: {
                  background: "#571b3c",
                  color: "#e7bdd3",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <List>
                {options.map((option, index) => (
                  <ListItem
                    button
                    key={index}
                    component={Link}
                    to={option.path}
                    selected={location.pathname === option.path}
                    onClick={toggleDrawer}
                    sx={{
                      "&.Mui-selected": {
                        background: "white",
                        color: "black",
                        "& .MuiListItemIcon-root": {
                          color: "black",
                        },
                      },
                    }}
                  >
                    <ListItemIcon sx={{ color: "white" }}>
                      {option.icon}
                    </ListItemIcon>
                    <ListItemText primary={option.label} />
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Hidden>
          <div id="logo">
            <Link to="/">
              <img src={logo} alt="Logo de la empresa" className="logo" />
            </Link>
          </div>
          <Hidden smDown>
            <List
              sx={{
                display: "flex",
                background: "#571b3c",
                color: "white",
                flexGrow: 1,
              }}
            >
              {options.map((option, index) => (
                <ListItem
                  button
                  key={index}
                  component={Link}
                  to={option.path}
                  selected={location.pathname === option.path}
                  onClick={toggleDrawer}
                  sx={{
                    "&.Mui-selected": {
                      background: "white",
                      color: "black",
                      textAlign: "center",
                      borderRadius: "10px",
                    },
                    width: "170px",
                    textAlign: "center", // Ajusta el ancho aquí según tus necesidades
                  }}
                >
                  <ListItemText primary={option.label} />
                </ListItem>
              ))}
            </List>
          </Hidden>
        </Toolbar>
      </div>
    </AppBar>
  );
};

export default Header;
