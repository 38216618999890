import "react-responsive-carousel/lib/styles/carousel.min.css";
import Typography from "@mui/material/Typography";
import casa3 from "../pictures/casa3.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import PhoneIcon from "@mui/icons-material/Phone";
import Gmail from "../pictures/gmail.png";
import Header from "./Header.js";
import Footer from "./Footer.js";
import "../css/normalize.css";
import "../css/Contact.css";

const Contact = () => {
  return (
    <>
      <Header />

      <div className="contact-container">
        <div className="image-info-container">
          <div className="logo-container">
            <img
              src={casa3}
              alt="Logo de la empresa"
              className="floating-image"
            />
          </div>

          <div className="contact-info">
            <Typography>
              <img
                src={Gmail}
                alt="Gmail"
                style={{ height: "21px", width: "21px", marginRight: "4px" }}
              />
              <strong>Gmail:</strong> inpro360info@gmail.com
              <br />
              <br />
              <InstagramIcon />
              <strong>Instagram:</strong>{" "}
              <a href="https://instagram.com/inpro_360?igshid=MzRlODBiNWFlZA==">instagram.com/inpro_360</a>
              <br />
              <br />
            </Typography>
            <Typography>
              <FacebookIcon />
              <strong>Facebook:</strong>{" "}
              <a href="https://www.facebook.com/profile.php?id=61550237374585&mibextid=LQQJ4d">facebook.com/inpro_360</a>
              <br />
              <br />
              <PhoneIcon />
              <strong>Teléfono:</strong>{" "}
              <a href="https://wa.link/jzk9pd">0984209888</a>
            </Typography>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
