import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Grid from "@mui/material/Grid";
import PerfectScrollbar from "react-perfect-scrollbar";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
  addLocalComercial,
  getTipoLocalComercial,
} from "../services/LocalComercial";
import "../css/normalize.css";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
  },
});

const ModalLocalComercial = ({ handleCloseModal }) => {
  const textFieldStyle = {
    marginBottom: "1rem",
    textAlign: "center",
  };

  let [nombreLocal, setNombreLocal] = useState("");
  let [descripcion, setDescripcion] = useState("");
  let [tipoLocalComercial, setTipoLocalComercial] = useState("");
  let [facebook, setFacebook] = useState("");
  let [instagram, setInstagram] = useState("");
  let [whatsapp, setWhatsapp] = useState("");
  let [contacto, setContacto] = useState("");
  let [pais, setPais] = useState("");
  let [ciudad, setCiudad] = useState("");
  let [sector, setSector] = useState("");
  let [kuula, setKuula] = useState("");
  let [googleMaps, setGoogleMaps] = useState("");

  const [tipoLocalComercialeOptions, setTipoLocalComercialOptions] = useState(
    []
  );
  const [paisesOptions, setPaisesOptions] = useState([]);
  const [ciudadesOptions, setCiudadesOptions] = useState([]);

  //VALIDACIONES
  const [descripcionError, setDescripcionError] = useState(""); // Para controlar el error de longitud

  const handleNombreLocalChange = (event) => {
    setNombreLocal(event.target.value);
  };

  const handleDescripcionChange = (event) => {
    const value = event.target.value;

    if (value.length > 900) {
      setDescripcionError("La descripción no debe exceder los 900 caracteres");
    } else {
      setDescripcion(value); // Solo actualiza la descripción si la longitud es válida
      setDescripcionError("");
    }
  };

  const handleTipoLocalComercialChange = (event) => {
    setTipoLocalComercial(event.target.value);
  };

  const handleFacebookChange = (event) => {
    setFacebook(event.target.value);
  };

  const handleInstagramChange = (event) => {
    setInstagram(event.target.value);
  };

  const handleWhatsappChange = (event) => {
    setWhatsapp(event.target.value);
  };

  const handleContactoChange = (event) => {
    setContacto(event.target.value);
  };

  const handlePaisChange = (event) => {
    setPais(event.target.value);
  };

  const handleCiudadChange = (event) => {
    setCiudad(event.target.value);
  };

  const handleSectorChange = (event) => {
    setSector(event.target.value);
  };

  const handleKuulaChange = (event) => {
    setKuula(event.target.value);
  };

  const handleGoogleMapsChange = (event) => {
    setGoogleMaps(event.target.value);
  };

  useEffect(() => {
    const fetchPaises = async () => {
      try {
        const response = await axios.get(
          "https://hogar360-project.vercel.app/api/paises"
        );
        const data = response.data;
        const sortedData = data.sort((a, b) =>
          a.nombrePais.localeCompare(b.nombrePais)
        );
        if (Array.isArray(sortedData) && sortedData.length > 0) {
          setPaisesOptions(sortedData || []);
        } else {
          setPaisesOptions([]);
        }
      } catch (error) {
        console.log("Error fetching countries:", error);
      }
    };

    fetchPaises();
  }, []);

  useEffect(() => {
    const fetchCiudades = async () => {
      try {
        const response = await axios.get(
          `https://hogar360-project.vercel.app/api/ciudadesId/${encodeURIComponent(
            pais
          )}`
        );
        const data = response.data;

        const sortedData = data.sort((a, b) =>
          a.nombreCiudad.localeCompare(b.nombreCiudad)
        );
        if (Array.isArray(sortedData) && sortedData.length > 0) {
          setCiudadesOptions(sortedData || []);
        } else {
          setCiudadesOptions([]);
        }
      } catch (error) {
        // console.log("Error fetching cities:", error);
      }
    };

    fetchCiudades();
  }, [pais]);

  useEffect(() => {
    const fetchTipoLocalComercial = async () => {
      try {
        const response = await getTipoLocalComercial();
        const data = response.data;
        const sortedData = data.sort((a, b) =>
        a.nombreTipoLocalComercial.localeCompare(b.nombreTipoLocalComercial)
        )
        if(Array.isArray(sortedData) && sortedData.length > 0){
          setTipoLocalComercialOptions(sortedData || []);
        }else{
          setTipoLocalComercialOptions([])
        }
       
      } catch (error) {
        console.log("Error fetching tipoLocalComercial:", error);
      }
    };

    fetchTipoLocalComercial();
  }, []);

  const handleGuardarClick = () => {
    // Aquí puedes recopilar los datos del formulario y enviarlos al servidor

    const formData = {
      nombreLocal: nombreLocal || "Pendiente",
      descripcion: descripcion || "Pendiente", 
      idTipoLocalComercial: tipoLocalComercial || "0",
      facebook: facebook || "Pendiente",
      instagram: instagram || "Pendiente",
      whatsApp: whatsapp || "Pendiente",
      contacto: contacto || "Pendiente",
      idPais: pais || "0",
      idCiudad: ciudad || "0",
      sector: sector || "Pendiente",
      linkKuula: kuula || "Pendiente",
      linkGoogleMaps: googleMaps || "Pendiente",
    };

    addLocalComercial(formData)
      .then((response) => {
        // Aquí puedes manejar la respuesta del servidor si es necesario
        alert("Local Comercial agregado con éxito", response);

        // Si quieres realizar alguna acción con la respuesta, puedes hacerlo aquí
        window.location.reload();
      })
      .catch((error) => {
        // Aquí puedes manejar los errores que ocurran en el POST
        alert("Error al agregar el local comercial", error);
        // Si quieres mostrar algún mensaje de error o realizar alguna acción, puedes hacerlo aquí
      });
    handleCloseModal();
  };
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <PerfectScrollbar style={{ maxHeight: "500px" }}>
          <Box sx={textFieldStyle}>
            <TextField
              id="nombreLocal"
              label="Nombre del Local Comercial"
              variant="filled"
              value={nombreLocal}
              onChange={handleNombreLocalChange}
            />
          </Box>
          <Box marginBottom="2rem">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="tipoLocalComercialSelect">
                    Tipo de Local Comercial
                  </InputLabel>
                  <Select
                    labelId="tipoLocalComercialSelect"
                    id="tipoLocalComercialSelect"
                    value={tipoLocalComercial}
                    onChange={handleTipoLocalComercialChange}
                    label="Tipo de Local Comercial"
                  >
                    {tipoLocalComercialeOptions
                      .filter((option) => option.id !== 0)
                      .map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.nombreTipoLocalComercial}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <TextField
              id="descripcion"
              label="Descripción"
              variant="filled"
              value={descripcion}
              onChange={handleDescripcionChange}
              error={descripcionError !== ""}
              helperText={descripcionError}
              multiline
              rows={4} // Puedes ajustar el número de filas según tus necesidades
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {`${descripcion.length}/900`}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <br />
          <Box marginBottom="2rem">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="paisSelectLabel">País</InputLabel>
                  <Select
                    labelId="paisSelectLabel"
                    id="paisSelect"
                    value={pais}
                    onChange={handlePaisChange}
                    label="País"
                  >
                    {paisesOptions
                      .filter((option) => option.id !== 0) // Filtrar las opciones con id distinto de 0
                      .map((option) => (
                        <MenuItem key={option.nombrePais} value={option.id}>
                          {option.nombrePais}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="ciudadSelect">Ciudad</InputLabel>
                  <Select
                    labelId="ciudadSelect"
                    id="ciudadSelect"
                    value={ciudad}
                    onChange={handleCiudadChange}
                    label="Ciudad"
                  >
                    {Array.isArray(ciudadesOptions) &&
                      ciudadesOptions.length > 0 &&
                      ciudadesOptions.map((options) => (
                        <MenuItem key={options.nombreCiudad} value={options.id}>
                          {options.nombreCiudad}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="sector"
                  label="Sector"
                  variant="filled"
                  onChange={handleSectorChange}
                />
              </Grid>
            </Grid>
          </Box>

          <Box marginBottom="2rem">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="facebook"
                  label="Facebook"
                  variant="filled"
                  onChange={handleFacebookChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="instagram"
                  label="Instagram"
                  variant="filled"
                  onChange={handleInstagramChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom="2rem">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="contacto"
                  label="Contacto"
                  variant="filled"
                  onChange={handleContactoChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="whatsapp"
                  label="WhatsApp"
                  variant="filled"
                  onChange={handleWhatsappChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ ...textFieldStyle, marginTop: "1rem" }}>
            <TextField
              id="kuula"
              label="Link de Kuula"
              onChange={handleKuulaChange}
              variant="filled"
            />
          </Box>
          <Box sx={{ ...textFieldStyle, marginTop: "1rem" }}>
            <TextField
              id="googleMaps"
              label="Link de Google Maps"
              onChange={handleGoogleMapsChange}
              variant="filled"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "40%",
                bgcolor: "#571b3c",
                color: "white",
                "&:hover": {
                  bgcolor: "white",
                  color: "#571b3c",
                },
              }}
              onClick={handleGuardarClick}
            >
              Guardar
            </Button>
          </Box>
        </PerfectScrollbar>
      </Box>
    </ThemeProvider>
  );
};

export default ModalLocalComercial;
