import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Inicio from "./componentes/Inicio";
import About from "./componentes/About";
import CatalogoView from "./componentes/CatalogoView";
import ComercialView from "./componentes/ComercialView";
import Comercial from "./componentes/Comercial";
import Inmueble from "./componentes/Inmueble";
import CRUDCatalogo from "./componentes/CRUDCatalogo";
import CRUDLocalComercial from "./componentes/CRUDLocalComercial";
import Contact from "./componentes/Contact";
import LoginForm from "./componentes/LoginForm";
import Cookies from "js-cookie";
import { decodeToken } from "react-jwt";

function App() {
  const token = Cookies.get("token");
  let userType = null;
  if (token) {
    const decodedToken = decodeToken(token);
    userType = decodedToken.userType;
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/About" element={<About />} />
        <Route path="/CatalogoView" element={<CatalogoView />} />
        <Route path="/ComercialView" element={<ComercialView />} />
        <Route path="/Comercial/:id" element={<Comercial />} />
        <Route path="/Inmueble/:id" element={<Inmueble />} />
        <Route
          path="/Inmueble"
          element={
            token && userType === 1 ? (
              <CRUDCatalogo />
            ) : (
              <Navigate to="/Admin" />
            )
          }
        />
          <Route
          path="/LocalComercial"
          element={
            token && userType === 1 ? (
              <CRUDLocalComercial />
            ) : (
              <Navigate to="/Admin" />
            )
          }
        />
        <Route path="/Contact" element={<Contact />} />
        <Route
          path="/Admin"
          element={token ? <Navigate to="/Inmueble" /> : <LoginForm />}
        />
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
