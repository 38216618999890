import axios from "axios";
import Cookies from "js-cookie";

const apiUrl = process.env.REACT_APP_API_URL;
const token = Cookies.get("token");

export async function getInmuebles() {
  try {
    const response = await axios.get(`${apiUrl}/api/inmuebles`);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function getInmueble(id) {
  try {
    const response = await axios.get(`${apiUrl}/api/inmueble/${id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function getTipoInmuebles() {
  try {
    if (!token) {
      throw new Error("No se encontró el token de autenticación");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Envía el token en el encabezado "Authorization"
      },
    };
    const response = await axios.get(`${apiUrl}/api/tipoInmuebles`, config);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function addInmueble(datos) {
  try {
    if (!token) {
      throw new Error("No se encontró el token de autenticación");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Envía el token en el encabezado "Authorization"
      },
    };
    const response = await axios.post(
      `${apiUrl}/api/addInmuebles`,
      datos,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
    // También puedes propagar el error para que pueda ser manejado en el componente que use esta función
    throw error;
  }
}

export async function updateInmueble(datos, id) {
  try {
    if (!token) {
      throw new Error("No se encontró el token de autenticación");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Envía el token en el encabezado "Authorization"
      },
    };
    const response = await axios.put(
      `${apiUrl}/api/updateInmueble/${id}`,
      datos,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function deleteInmueble(id) {
  try {
    if (!token) {
      throw new Error("No se encontró el token de autenticación");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Envía el token en el encabezado "Authorization"
      },
    };
    const response = await axios.delete(
      `${apiUrl}/api/deleteInmueble/${id}`,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getInmueblesCatalogo() {
  try {
    const response = await axios({
      url: `${apiUrl}/api/inmueblesCatalogo`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.log(error.response?.data); // Imprime los datos del error
  }
}

export async function getInmuebleCatalogo(id) {
  try {
    const response = await axios({
      url: `${apiUrl}/api/inmueblesCatalogo/${id}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.log(error.response?.data); // Imprime los datos del error
  }
}
