import React from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import casa1 from "../pictures/Casa-Inicio-1.jpg";
import casa2 from "../pictures/Casa-Inicio-2.jpg";
import casa3 from "../pictures/Casa-Inicio-3.png";
import casa4 from "../pictures/Casa-Inicio-4.png";
import "../css/Inicio.css";
import "../css/normalize.css";

const Inicio = () => {
  return (
    <>
      <Header />
      <div className="carousel-container">
        <Carousel
          showStatus={false}
          showIndicators={true}
          showThumbs={false}
          renderArrowNext={(onClickHandler) => (
            <button
              type="button"
              aria-label="next slide / item"
              className="control-arrow control-next"
              onClick={onClickHandler}
            ></button>
          )}
          renderArrowPrev={(onClickHandler) => (
            <button
              type="button"
              aria-label="previous slide / item"
              className="control-arrow control-prev"
              onClick={onClickHandler}
            ></button>
          )}
        >
          <div>
            <img src={casa1} alt="Imagen 2" />
          </div>
          <div>
            <img src={casa2} alt="Imagen 3" />
          </div>
        </Carousel>
      </div>
      <div id="informacion" className="info-container">
        <div className="info-card">
          <Card
            sx={{
              display: "flex",
              width: "100%",
              marginTop: "10px",
              boxShadow: "0 6px 10px 4px rgba(0, 0, 0, 0.2)",
            }}
          >
            <CardActionArea
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <CardMedia
                component="img"
                height="140"
                style={{ width: "50%", objectFit: "cover" }}
                image={casa3}
              />
              <div className="info-text">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Servicio Innovador
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Nos encontramos en la vanguardia del mercado inmobiliario,
                    transformando por completo la manera en que se compran y
                    venden propiedades. Nuestra innovación radica en los
                    recorridos virtuales 360°, una tecnología disruptiva que te
                    permite explorar propiedades desde la comodidad de tu hogar
                    u oficina. Ya no necesitas visitas presenciales para conocer
                    una propiedad en su totalidad. Con nuestra plataforma,
                    puedes acceder a cada rincón y detalle de una propiedad de
                    manera inmersiva y emocionante. Este enfoque avanzado
                    agiliza la toma de decisiones, hace que el proceso de
                    transacción de bienes raíces sea más eficiente y
                    transparente, y te brinda una experiencia inigualable en la
                    búsqueda de tu próximo hogar o inversión.
                  </Typography>
                </CardContent>
              </div>
            </CardActionArea>
          </Card>
        </div>
        <div className="info-card">
          <Card
            sx={{
              display: "flex",
              width: "100%",
              marginTop: "10px",
              boxShadow: "0 6px 10px 4px rgba(0, 0, 0, 0.2)",
            }}
          >
            <CardActionArea
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="info-text">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Recorridos 360
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Sumérgete en la experiencia de explorar propiedades como
                    nunca antes. Nuestros recorridos virtuales 360° te
                    transportan a una dimensión donde cada rincón, habitación y
                    paisaje exterior de una propiedad cobra vida ante tus ojos.
                    La sensación de estar allí en persona es asombrosamente
                    real, lo que te permite tomar decisiones informadas sobre tu
                    inversión inmobiliaria sin tener que realizar visitas
                    físicas. Con nuestros recorridos, puedes comparar
                    propiedades y descubrir detalles que antes pasaban
                    desapercibidos. Bienvenido a la revolución de los recorridos
                    360° en el mercado de bienes raíces, donde la confianza y la
                    eficiencia son la norma, y la exploración se convierte en
                    una experiencia emocionante y conveniente.
                  </Typography>
                </CardContent>
              </div>
              <CardMedia
                component="img"
                height="140"
                style={{ width: "50%", objectFit: "cover", marginbotoom: "1%" }}
                image={casa4}
              />
            </CardActionArea>
          </Card>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Inicio;
