import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import Cookies from "js-cookie";
import logo2 from "../pictures/logo2.png";
import "../css/AdminHeader.css";

const HeaderAdmin = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const location = useLocation();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const options = [
    {
      label: "Locales Comerciales",
      path: "/LocalComercial", // Updated path to the correct one
    },
    {
      label: "Compra / Venta",
      path: "/Inmueble", // Updated path to the correct one
    },
    {
      label: "Salir",
      icon: <HomeIcon />,
      path: "/Admin",
    },
  ];

  const handleLogout = () => {
    Cookies.remove("token");
    window.location.href = "/Admin";
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#571b3c" }}>
      <div id="menus" backgroundColor="#571b3c">
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer}
              PaperProps={{
                sx: {
                  backgroundColor: "#571b3c",
                  color: "#e7bdd3",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <List>
                {options.map((option, index) => (
                  <ListItem
                    button
                    key={index}
                    component={Link} // Use Link from React Router
                    to={option.path} // Set the correct path for the Link
                    selected={location.pathname === option.path}
                    onClick={() => {
                      toggleDrawer();
                    }}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "white",
                        color: "black",
                        "& .MuiListItemIcon-root": {
                          color: "black",
                        },
                      },
                    }}
                  >
                    <ListItemIcon sx={{ color: "white" }}>
                      {option.icon}
                    </ListItemIcon>
                    <ListItemText primary={option.label} />
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Hidden>
          <div id="logo">
            <Link to="/">
              <img src={logo2} alt="Logo de la empresa" className="adminLogo" />
            </Link>
          </div>
          <Hidden smDown>
            <List
              sx={{
                display: "flex",
                backgroundColor: "#571b3c",
                color: "white",
                flexGrow: 1,
              }}
            >
              {options.map((option, index) => (
                <ListItem
                  button
                  key={index}
                  component={Link}
                  to={option.path}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "white",
                      color: "black",
                      textAlign: "center",
                      borderRadius: "10px",
                    },
                    textAlign: "center",
                  }}
                  onClick={() => {
                    if (option.path === "/Admin") {
                      handleLogout();
                    } else {
                      toggleDrawer();
                    }
                  }}
                >
                  <ListItemText primary={option.label} />
                </ListItem>
              ))}
            </List>
          </Hidden>
        </Toolbar>
      </div>
    </AppBar>
  );
};

export default HeaderAdmin;
