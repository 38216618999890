import * as React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Typography from "@mui/material/Typography";
import LazyLoad from "react-lazy-load";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getInmuebleCatalogo } from "../services/FormularioInmuebles.js"; // Importar la función getInmueble
import "../css/Catalogo.css";

const ComponenteCatalogo = ({ inmuebleId }) => {
  // Pasar el ID del inmueble como prop

  const [inmuebleData, setInmuebleData] = React.useState(null);

  React.useEffect(() => {
    // Cargar los detalles del inmueble al montar el componente
    async function fetchInmuebleData() {
      try {
        const response = await getInmuebleCatalogo(inmuebleId);
        setInmuebleData(response.data[0]);
        console.log(inmuebleId);
      } catch (error) {
        console.log(error);
        // Puedes mostrar un mensaje al usuario indicando que ocurrió un error en la solicitud
        // O realizar cualquier otra acción de manejo de errores que consideres adecuada.
      }
    }
    fetchInmuebleData();
  }, [inmuebleId]);

  return (
    <>
      {inmuebleData && ( // Mostrar el contenido una vez que se hayan obtenido los datos
        <div className="catalogue-container">
          <LazyLoad>
            <div className="contenedor-catalogo-kula-informacion">
              <div className="contenedor-kula">
                <iframe
                  title="kuula"
                  className="kula"
                  allow="xr-spatial-tracking; gyroscope; accelerometer"
                  allowFullScreen
                  src={inmuebleData.kuula} // Usar el link del inmueble desde la base de datos
                ></iframe>
              </div>



              <div className="contact-info">
                <Typography>
                  <strong className="catalog-info">Proyecto:</strong>{" "}
                  {inmuebleData.nombreProyecto}
                  <br />
                  <strong className="catalog-info">Precio: </strong>
                  {"$ "}
                  {inmuebleData.precio}
                  <br />
                  <strong className="catalog-info">Tipo Inmueble:</strong>{" "}
                  {inmuebleData.nombreTipoInmueble}
                  <br />
                  <strong className="catalog-info">País:</strong>{" "}
                  {inmuebleData.pais}
                  <br />
                  <strong className="catalog-info">Ciudad:</strong>{" "}
                  {inmuebleData.ciudad}
                  <br />
                  <strong className="catalog-info">Sector:</strong>{" "}
                  {inmuebleData.sector}
                  <br />
                </Typography>
                <Typography>
                  <strong className="catalog-info">
                    Metros de Construcción:
                  </strong>{" "}
                  {inmuebleData.metrosConstruccion} m<sup>2</sup>
                  <br />
                  <strong className="catalog-info">Plantas:</strong>{" "}
                  {inmuebleData.plantas}
                  <br />
                  <strong className="catalog-info">Habitaciones:</strong>{" "}
                  {inmuebleData.habitaciones}
                  <br />
                  <strong className="catalog-info">Baños:</strong>{" "}
                  {inmuebleData.banos}
                  <br />
                  <strong className="catalog-info">Antiguedad:</strong>{" "}
                  {inmuebleData.antiguedad} años
                  <br />
                  <a href={`${inmuebleData.whatsapp}`}>
                    <FaWhatsapp
                      style={{
                        fontSize: "24px",
                        marginRight: "5px",
                        color: "green",
                      }}
                    />
                  </a>
                  {inmuebleData.contacto}
                  <br />
                </Typography>
                <div className="contenedor-mas-informacion-catalogo">
              <Link
                  to={`/Inmueble/${inmuebleId}`}
                  className="mas-informacion-boton"
                >
                  Más Información
                </Link>
              </div>
              </div>

            </div>
          </LazyLoad>
        </div>
      )}
      <h2 className="encabezado-con-linea"></h2>
    </>
  );
};

export default ComponenteCatalogo;
