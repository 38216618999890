import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Grid from "@mui/material/Grid";
import PerfectScrollbar from "react-perfect-scrollbar";
import Button from "@mui/material/Button";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
  updateInmueble,
  getTipoInmuebles,
  getInmueble,
} from "../services/FormularioInmuebles";
import "../css/normalize.css";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
  },
});

const ModalEditInmueble = ({ handleCloseModal, id }) => {
  const textFieldStyle = {
    marginBottom: "1rem",
    textAlign: "center",
  };

  let [nombreProyecto, setNombreProyecto] = useState("");
  let [tipoInmueble, setTipoInmueble] = useState("");
  let [metrosConstruccion, setMetrosConstruccion] = useState("");
  let [metrosTerreno, setMetrosTerreno] = useState("");
  let [precio, setPrecio] = useState("");
  let [habitaciones, setHabitaciones] = useState("");
  let [banos, setBanos] = useState("");
  let [plantas, setPlantas] = useState("");
  let [estacionamientos, setEstacionamientos] = useState("");
  let [antiguedad, setAntiguedad] = useState("");
  let [pais, setPais] = useState("");
  let [ciudad, setCiudad] = useState("");
  let [sector, setSector] = useState("");
  let [contacto, setContacto] = useState("");
  let [whatsapp, setWhatsapp] = useState("");
  let [disponibilidad, setDisponibilidad] = useState("");
  let [kuula, setKuula] = useState("");
  let [googleMaps, setGoogleMaps] = useState("");
  let [imagenes, setImagenes] = useState("");

  let [tipoInmuebleOptions, setTipoInmuebleOptions] = useState([]);
  let [paisesOptions, setPaisesOptions] = useState([]);
  let [ciudadesOptions, setCiudadesOptions] = useState([]);

  useEffect(() => {
    const fetchInmueble = async () => {
      try {
        const response = await getInmueble(id);
        const data = response.data[0];
        setNombreProyecto(data.nombreProyecto || "");
        setTipoInmueble(data.idTipoInmueble || "");
        setMetrosConstruccion(data.metrosConstruccion || "");
        setMetrosTerreno(data.metrosTerreno || "");
        setPrecio(data.precio || "");
        setHabitaciones(data.habitaciones || "");
        setBanos(data.banos || "");
        setPlantas(data.plantas || "");
        setEstacionamientos(data.estacionamientos || "");
        setAntiguedad(data.antiguedad || "");
        setPais(data.pais || "");
        setCiudad(data.ciudad || "");
        setSector(data.sector || "");
        setContacto(data.contacto || "");
        setWhatsapp(data.whatsapp || "");
        setDisponibilidad(data.disponibilidad || "");
        setKuula(data.kuula || "");
        setGoogleMaps(data.googleMaps || "");
        setImagenes(data.imagenes || "")
      } catch (error) {
        console.log("Error fetching Inmueble:", error);
      }
    };

    fetchInmueble();
  }, [id]);

  const handleNombreProyectoChange = (event) => {
    setNombreProyecto(event.target.value);
  };

  const handleTipoInmuebleChange = (event) => {
    setTipoInmueble(event.target.value);
  };

  const handleMetrosConstruccionChange = (event) => {
    setMetrosConstruccion(event.target.value);
  };

  const handleMetrosTerrenoChange = (event) => {
    setMetrosTerreno(event.target.value);
  };

  const handlePrecioChange = (event) => {
    setPrecio(event.target.value);
  };

  const handleHabitacionesChange = (event) => {
    setHabitaciones(event.target.value);
  };

  const handleBanosChange = (event) => {
    setBanos(event.target.value);
  };

  const handlePlantasChange = (event) => {
    setPlantas(event.target.value);
  };

  const handleEstacionamientosChange = (event) => {
    setEstacionamientos(event.target.value);
  };

  const handleAntiguedadChange = (event) => {
    setAntiguedad(event.target.value);
  };

  const handlePaisChange = (event) => {
    setPais(event.target.value);
  };

  const handleCiudadChange = (event) => {
    setCiudad(event.target.value);
  };

  const handleSectorChange = (event) => {
    setSector(event.target.value);
  };

  const handleContactoChange = (event) => {
    setContacto(event.target.value);
  };

  const handleWhatsappChange = (event) => {
    setWhatsapp(event.target.value);
  };

  const handleDisponibilidadChange = (event) => {
    setDisponibilidad(event.target.value);
  };

  const handleKuulaChange = (event) => {
    setKuula(event.target.value);
  };

  const handleGoogleMapsChange = (event) => {
    setGoogleMaps(event.target.value);
  };

  useEffect(() => {
    const fetchPaises = async () => {
      try {
        const response = await axios.get(
          "https://hogar360-project.vercel.app/api/paises"
        );
        const data = response.data;
        const sortedData = data.sort((a, b) =>
          a.nombrePais.localeCompare(b.nombrePais)
        );
        if (Array.isArray(sortedData) && sortedData.length > 0) {
          setPaisesOptions(sortedData || []);
        } else {
          setPaisesOptions([]);
        }
      } catch (error) {
        console.log("Error fetching countries:", error);
      }
    };

    fetchPaises();
  }, []);

  useEffect(() => {
    const fetchCiudades = async () => {
      try {
        const response = await axios.get(
          `https://hogar360-project.vercel.app/api/ciudades/${encodeURIComponent(
            pais
          )}`
        );
        const data = response.data;
        const sortedData = data.sort((a, b) =>
          a.nombreCiudad.localeCompare(b.nombreCiudad)
        );
        if (Array.isArray(sortedData) && sortedData.length > 0) {
          setCiudadesOptions(sortedData || []);
        } else {
          setCiudadesOptions([]);
        }
      } catch (error) {
        // console.log("Error fetching cities:", error);
      }
    };

    fetchCiudades();
  }, [pais]);

  useEffect(() => {
    const fetchTipoInmuebles = async () => {
      try {
        const response = await getTipoInmuebles();
        const data = response.data;
        const sortedData = data.sort((a, b) =>
          a.nombreTipoInmueble.localeCompare(b.nombreTipoInmueble)
        );
        if (Array.isArray(sortedData) && sortedData.length > 0) {
          setTipoInmuebleOptions(sortedData || []);
        } else {
          setTipoInmuebleOptions([]);
        }
      } catch (error) {
        console.log("Error fetching tipoInmuebles:", error);
      }
    };

    fetchTipoInmuebles();
  }, []);

  const handleGuardarClick = () => {
    // Aquí puedes recopilar los datos del formulario y enviarlos al servidor

    const formData = {
      nombreProyecto: nombreProyecto || "Pendiente",
      idTipoInmueble: tipoInmueble || "0",
      metrosConstruccion: metrosConstruccion || "Pendiente",
      metrosTerreno: metrosTerreno || "Pendiente",
      precio: precio || "Pendiente",
      habitaciones: habitaciones || "0",
      banos: banos || "0",
      plantas: plantas || "0",
      estacionamientos: estacionamientos || "0",
      antiguedad: antiguedad || "0",
      pais: pais || "Pendiente",
      ciudad: ciudad || "Pendiente",
      sector: sector || "Pendiente",
      contacto: contacto || "Pendiente",
      whatsapp: whatsapp || "Pendiente",
      disponibilidad: disponibilidad || "0",
      kuula: kuula || "",
      googleMaps: googleMaps || "",
      imagenes: imagenes || "",
    };

    updateInmueble(formData, id)
      .then((response) => {
        // Aquí puedes manejar la respuesta del servidor si es necesario
        alert("Inmueble actualizado con éxito", response);

        // Si quieres realizar alguna acción con la respuesta, puedes hacerlo aquí
        window.location.reload();
      })
      .catch((error) => {
        // Aquí puedes manejar los errores que ocurran en el POST
        console.log("Error al agregar inmueble:", error);
        // Si quieres mostrar algún mensaje de error o realizar alguna acción, puedes hacerlo aquí
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <PerfectScrollbar style={{ maxHeight: "500px" }}>
          <Box sx={textFieldStyle}>
            <TextField
              id="nombreProyecto"
              label="Nombre del Proyecto"
              variant="filled"
              value={nombreProyecto}
              onChange={handleNombreProyectoChange}
            />
          </Box>
          <Box marginBottom="2rem">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="tipoInmuebleSelect">Tipo Inmueble</InputLabel>
                  <Select
                    labelId="tipoInmuebleSelect"
                    id="tipoInmuebleSelect"
                    value={tipoInmueble}
                    onChange={handleTipoInmuebleChange}
                    label="Tipo Inmueble"
                  >
                    {tipoInmuebleOptions
                      .filter((option) => option.id !== 0)
                      .map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.nombreTipoInmueble}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box marginBottom="2rem">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  id="metrosConstruccion"
                  label="Metros de Construcción"
                  variant="filled"
                  value={metrosConstruccion}
                  onChange={handleMetrosConstruccionChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="metrosTerreno"
                  label="Metros del Terreno"
                  variant="filled"
                  value={metrosTerreno}
                  onChange={handleMetrosTerrenoChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="precio"
                  label="Precio"
                  variant="filled"
                  value={precio}
                  onChange={handlePrecioChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="habitaciones"
                  label="Habitaciones"
                  variant="filled"
                  type="number"
                  value={habitaciones}
                  onChange={handleHabitacionesChange}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="banos"
                  label="Baños"
                  variant="filled"
                  type="number"
                  value={banos}
                  onChange={handleBanosChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="plantas"
                  label="Plantas"
                  variant="filled"
                  type="number"
                  value={plantas}
                  onChange={handlePlantasChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="estacionamientos"
                  label="Estacionamientos"
                  variant="filled"
                  type="number"
                  value={estacionamientos}
                  onChange={handleEstacionamientosChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="antiguedad"
                  label="Antiguedad"
                  variant="filled"
                  type="number"
                  value={antiguedad}
                  onChange={handleAntiguedadChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom="2rem">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="paisSelectLabel">País</InputLabel>
                  <Select
                    labelId="paisSelectLabel"
                    id="paisSelect"
                    value={pais}
                    onChange={handlePaisChange}
                    label="País"
                  >
                    {paisesOptions
                      .filter((option) => option.id !== 0)
                      .map((option) => (
                        <MenuItem
                          key={option.nombrePais}
                          value={option.nombrePais}
                        >
                          {option.nombrePais}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="ciudadSelect">Ciudad</InputLabel>
                  <Select
                    labelId="ciudadSelect"
                    id="ciudadSelect"
                    value={ciudad}
                    onChange={handleCiudadChange}
                    label="Ciudad"
                  >
                    {Array.isArray(ciudadesOptions) &&
                      ciudadesOptions.length > 0 &&
                      ciudadesOptions.map((options) => (
                        <MenuItem
                          key={options.nombreCiudad}
                          value={options.nombreCiudad}
                        >
                          {options.nombreCiudad}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="sector"
                  label="Sector"
                  variant="filled"
                  value={sector}
                  onChange={handleSectorChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom="2rem">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="contacto"
                  label="Contacto"
                  variant="filled"
                  value={contacto}
                  onChange={handleContactoChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="whatsapp"
                  label="Whatsapp"
                  variant="filled"
                  value={whatsapp}
                  onChange={handleWhatsappChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="disponibilidadSelect">
                    Disponibilidad
                  </InputLabel>
                  <Select
                    labelId="disponibilidadSelect"
                    id="disponibilidadSelect"
                    value={disponibilidad}
                    onChange={handleDisponibilidadChange}
                    label="Disponibilidad"
                  >
                    <MenuItem value={1}>Si</MenuItem>
                    <MenuItem value={2}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ ...textFieldStyle, marginTop: "1rem" }}>
            <TextField
              id="kuula"
              label="Link de Kuula"
              value={kuula}
              onChange={handleKuulaChange}
              variant="filled"
            />
          </Box>
          <Box sx={{ ...textFieldStyle, marginTop: "1rem" }}>
            <TextField
              id="googleMaps"
              label="Link de Google Maps"
              value={googleMaps}
              onChange={handleGoogleMapsChange}
              variant="filled"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "40%",
                bgcolor: "black",
                color: "white",
                "&:hover": {
                  bgcolor: "white",
                  color: "black",
                },
              }}
              onClick={handleGuardarClick}
            >
              Guardar
            </Button>
          </Box>
        </PerfectScrollbar>
      </Box>
    </ThemeProvider>
  );
};

export default ModalEditInmueble;
