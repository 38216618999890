import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  TablePagination,
  TextField,
  InputAdornment,
  Modal,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import { Search as SearchIcon } from "@mui/icons-material";
import {
  getLocalComercial,
  deleteLocalComercial,
} from "../services/LocalComercial";
import EditLocalComercialModal from "./EditLocalComercialModal.js"
import "../css/tableStyles.css";
import "../css/normalize.css";

const style = {
  position: "absolute",
  top: "10%",
  left: "50%",
  transform: "translateX(-50%)",
  width: "70%",
  maxWidth: "70vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TableLocalComercial = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableData, setTableData] = useState([]); // Estado para almacenar los datos
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");

  // Estados para manejar el modal de edición
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);

  const [selectedLocalComercialId, setSelectedLocalComercialId] =
    useState(null);

  const handleOpenModal = (id) => {
    setSelectedLocalComercialId(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getLocalComercial(); // Llama a la función getInmuebles para obtener los datos
        setTableData(result.data); // Actualiza el estado tableData con los datos obtenidos
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar este registro?"
    );
    if (confirmDelete) {
      try {
        const response = await deleteLocalComercial(id);
        if (response && response.status === 200) {
          // Eliminación exitosa, actualizamos la tabla con los datos actualizados
          const updatedData = tableData.filter((row) => row.id !== id);
          setTableData(updatedData);
        } else {
          console.log("Error al eliminar el registro.");
        }
      } catch (error) {
        console.log("Error al eliminar el registro:", error);
      }
    }
  };

  useEffect(() => {
    const filteredResult = tableData.filter((row) =>
      row.nombreLocal.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredData(filteredResult);
    setPage(0);
  }, [searchText, tableData]);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
    setPage(0); // Reiniciar la paginación al realizar una búsqueda
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

  return (
    <div>
      <TextField
        variant="outlined"
        placeholder="Buscar por nombre del local comercial"
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        style={{ width: "30%", marginBottom: "20px" }}
      />
      <div >
        <TableContainer
          component={Paper}
          style={{
            maxHeight: "450px",
            maxWidth: "1200px",
            border: "1px solid black",
          }}
        >
          <Table stickyHeader>
            <TableHead style={{ background: "#571b3c" }}>
              <TableRow>
                <TableCell
                  className="table-cell"
                  style={{
                    backgroundColor: "#571b3c",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  fontWeight="bold"
                >
                  Nombre del Local
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{
                    backgroundColor: "#571b3c",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  Tipo de Local Comercial
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{
                    backgroundColor: "#571b3c",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  País
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{
                    backgroundColor: "#571b3c",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  Ciudad
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{
                    backgroundColor: "#571b3c",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  Sector
                </TableCell>
                <TableCell
                  className="table-cell"
                  style={{
                    backgroundColor: "#571b3c",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell className="table-cell">
                      {row.nombreLocal}
                    </TableCell>
                    <TableCell className="table-cell">
                      {row.nombreTipoLocalComercial}
                    </TableCell>
                    <TableCell className="table-cell">
                      {row.nombrePais}
                    </TableCell>
                    <TableCell className="table-cell">
                      {row.nombreCiudad}
                    </TableCell>
                    <TableCell className="table-cell">{row.sector}</TableCell>

                    <TableCell className="table-cell">
                      <IconButton
                        style={{ color: "red" }}
                        onClick={() => handleDelete(row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>

                      <IconButton
                        style={{ color: "blue" }}
                        onClick={() => handleOpenModal(row.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <EditLocalComercialModal
            handleClose={handleCloseModal}
            id={selectedLocalComercialId}
          />
        </Box>
      </Modal>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TableLocalComercial;
