import React, { useState, useEffect } from "react";
import ComponenteComercial from "./ComponenteComercial.js";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { getCatalogoLocalComercial } from "../services/LocalComercial.js";
import { Typography, TextField, Button, Menu, MenuItem } from "@mui/material";
import giphyGif from "../pictures/giphy.gif";
import "../css/ComercialView.css";

const ComercialView = () => {
  const [comercialList, setComercialList] = useState([]);
  const [filteredComerciales, setFilteredComerciales] = useState([]);
  const [filters, setFilters] = useState({
    nombreLocal: "",
    tipoComercial: "",
    ciudad: "",
    sector: "",
  });

  useEffect(() => {
    async function fetchComercialData() {
      const response = await getCatalogoLocalComercial();
      setComercialList(response.data);
    }
    fetchComercialData();
  }, []);

  useEffect(() => {
    const filtered = comercialList.filter((comercial) => {
      return (
        (!filters.nombreLocal ||
          parseInt(comercial.nombreLocal) === parseInt(filters.nombreLocal)) &&
        (!filters.tipoComercial ||
          comercial.nombretipoComercial
            .toLowerCase()
            .includes(filters.tipoComercial.toLowerCase())) &&
        (!filters.ciudad ||
          comercial.ciudad
            .toLowerCase()
            .includes(filters.ciudad.toLowerCase())) &&
        (!filters.sector ||
          parseInt(comercial.sector) === parseInt(filters.sector))
      );
    });

    setFilteredComerciales(filtered);
  }, [comercialList, filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if (
      name === "nombreLocal" ||
      name === "tipoComercial" ||
      name === "ciudad" ||
      name === "sector"
    ) {
      if (!isNaN(value)) {
        setFilters({ ...filters, [name]: value });
      }
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  const handleFilterReset = () => {
    setFilters({
      nombreLocal: "",
      tipoComercial: "",
      ciudad: "",
      sector: "",
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Desplazamiento suave
    });
  };

  const [isLoading, setIsLoading] = useState(true); // Estado para controlar la carga

  useEffect(() => {
    async function fetchComercialData() {
      const response = await getCatalogoLocalComercial();
      setComercialList(response.data);

      // Agregar un retraso de 1 segundo antes de cambiar isLoading a false
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    fetchComercialData();
  }, []);

  return (
    <>
      <Header />
      {/* Filtros de búsqueda */}
      <div className="componente-filtros">
        {/* Primera fila de filtros */}
        <div className="filtros-row">
          {/* Filtros de Nombre del Local */}
          <TextField
            label="Nombre del Local"
            variant="outlined"
            name="nombreLocal"
            value={filters.nombreLocal}
            onChange={handleFilterChange}
          />
          {/* Filtros de Tipo de Comercial*/}
          <TextField
            label="Tipo de Comercial"
            variant="outlined"
            name="tipoComercial"
            value={filters.tipoComercial}
            onChange={handleFilterChange}
          />
        </div>
        {/* Segunda fila de filtros */}
        <div className="filtros-row2">
          {/* Filtros de ciudad */}
          <TextField
            label="Ciudad"
            variant="outlined"
            name="Ciudad"
            value={filters.ciudad}
            onChange={handleFilterChange}
            type="text"
            inputProps={{ pattern: "[0-9]*" }}
          />
          {/* Filtros de Sector */}
          <TextField
            label="Sector"
            variant="outlined"
            name="sector"
            value={filters.sector}
            onChange={handleFilterChange}
            type="text"
            inputProps={{ pattern: "[0-9]*" }}
          />

          {/* Botón Limpiar */}
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#571b3c",
              color: "white",
              height: "93%",
            }}
            onClick={handleFilterReset}
          >
            Limpiar
          </Button>
        </div>
      </div>
      {/* Mostrar el GIF mientras isLoading sea true */}
      {isLoading ? (
        <div className="loading-container">
          <div className="loading-gif">
            <img src={giphyGif} alt="Cargando..." />
          </div>
        </div>
      ) : (
        /* Resultados de búsqueda */
        <div>
          {filteredComerciales.length > 0 ? (
            filteredComerciales.map((comercial) => (
              <ComponenteComercial
                key={comercial.id}
                comercialId={comercial.id}
              />
            ))
          ) : (
            <Typography variant="h6">
              No se han encontrado locales comerciales que coincidan con los filtros.
            </Typography>
          )}
        </div>
      )}
      {/* Botón flotante para volver al inicio */}
      <div className="scroll-to-top">
        <Button
          variant="contained"
          color="primary"
          className="boton-volver-inicio"
          onClick={handleScrollToTop}
          style={{
            position: "fixed", // Agregamos posición fija
            right: "4%",
            bottom: "9%",
            borderRadius: "50%",
            width: "4%",
            height: "6%",
            backgroundColor: "#571b3c",
            color: "#fff",
            fontSize: "24px", // Aumentamos el tamaño de la letra
            lineHeight: "1", // Ajustamos el espaciado entre líneas
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ↑
        </Button>
      </div>

      <Footer />
    </>
  );
};

export default ComercialView;
