import axios from "axios";
import Cookies from "js-cookie";

const apiUrl = process.env.REACT_APP_API_URL;
const token = Cookies.get("token");

export async function getLocalComercial() {
  try {
    const response = await axios.get(`${apiUrl}/api/localComercial`);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function getLocalComercialById(id) {
  try {
    const response = await axios.get(`${apiUrl}/api/localComercial/${id}`); 
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function getCatalogoLocalComercial() {
  try {
    const response = await axios({
      url: `${apiUrl}/api/catalogoLocalComercial`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.log(error.response?.data); // Imprime los datos del error
  }
}

export async function getCatalogoLocalComercialById(id) {
  try {
    const response = await axios({
      url: `${apiUrl}/api/catalogoLocalComercial/${id}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.log(error.response?.data); // Imprime los datos del error
  }
}

export async function addLocalComercial(datos) {
  try {
    if (!token) {
      throw new Error("No se encontró el token de autenticación");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Envía el token en el encabezado "Authorization"
      },
    };
    const response = await axios.post(`${apiUrl}/api/addLocalComercial`, datos, config);
    return response;
  } catch (error) {
    console.log(error);
    // También puedes propagar el error para que pueda ser manejado en el componente que use esta función
    throw error;
  }
}

export async function updateLocalComercial(datos,id) {
  try {
    if (!token) {
      throw new Error("No se encontró el token de autenticación");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Envía el token en el encabezado "Authorization"
      },
    };
    const response = await axios.put(`${apiUrl}/api/updateLocalComercial/${id}`, datos,config);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function deleteLocalComercial(id) {
  try {
    if (!token) {
      throw new Error("No se encontró el token de autenticación");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Envía el token en el encabezado "Authorization"
      },
    };
    const response = await axios.delete(`${apiUrl}/api/deleteLocalComercial/${id}`, config);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

//SERVICIO DE TIPO DE LOCAL COMERCIAL

export async function getTipoLocalComercial() {
  try {
    if (!token) {
      throw new Error("No se encontró el token de autenticación");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Envía el token en el encabezado "Authorization"
      },
    };
    const response = await axios.get(`${apiUrl}/api/tipoLocalComercial`, config);
    return response;
  } catch (error) {
    console.log(error);
  }
}