import React from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import casa1 from "../pictures/Casa-Quienes-Somos-1.png";
import casa2 from "../pictures/Casa-Quienes-Somos-2.png";
import "../css/normalize.css";
import "../css/About.css";

const About = () => {
  return (
    <>
      <Header />
      <div className="carousel-container">
        <Carousel
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          renderArrowNext={(onClickHandler) => (
            <button
              type="button"
              aria-label="next slide / item"
              className="control-arrow control-next"
              onClick={onClickHandler}
            ></button>
          )}
        >
          <div className="imagen-contenedor">
            <img src={casa1} alt="Imagen 2" />
          </div>
        </Carousel>
      </div>
      <div className="info-card">
        <Card
          sx={{
            display: "flex",
            width: "100%",
            boxShadow: "0 6px 10px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <CardActionArea
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="info-text">
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  ¿QUIÉNES SOMOS?
                </Typography>
                <div className="justificado">
                  <Typography variant="body2" color="text.secondary">
                    Somos una empresa emergente en el sector de bienes raíces,
                    basamos nuestros servicios en tecnología de recorridos
                    virtuales 360° a través de una plataforma digital de oferta
                    de inmuebles.
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Ofrecemos al usuario una experiencia inmversiva y
                    emocionante a través de nuestro tour virtual en línea que
                    permite explorar propiedades de manera más conveniente y
                    eficiente, desde la comodidad de sus hogares o lugares de
                    trabajo. Nuestra plataforma en línea ofrece una amplia gama
                    de características únicas y herramientas interactivas que
                    ayuda a los interesados a encontrar la propiedad perfecta.
                    Con nuestros recorridos virtuales, el usuario puede ver cada
                    detalle la propiedad, desde cada una las habitaciones hasta
                    los paisajes exteriores, permitiendo tomar una decisión más
                    informada sobre su inversión inmobiliaria
                  </Typography>
                </div>
              </CardContent>
            </div>
            <CardMedia component="img" height="140" image={casa2} />
          </CardActionArea>
        </Card>
      </div>
      <div
        id="informacion"
        style={{
          width: "90%",
          margin: "0 auto",
          marginTop: "20px",
          marginBottom: "1%",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="mision-vision-content">
            <Card className="info-item">
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  MISIÓN
                </Typography>
                <div className="justificado">
                  <Typography variant="body2" color="text.secondary">
                    Desarrollar y administrar la primera plataforma digital de
                    oferta de inmuebles en la provincia de Tungurahua que
                    proporcione una experiencia innovadora y única de compra y
                    venta de propiedades a través de recorridos virtuales 360°,
                    brindando una herramienta tecnológica avanzada que permita
                    acceder a cada detalle de una propiedad; logrando que el
                    comprador la conozca sin la necesidad de una visita
                    presencial; ayudando en su decisión de compra y haciendo el
                    proceso de transacción de bienes raíces más eficiente,
                    transparente y satisfactorio.
                  </Typography>
                </div>
              </CardContent>
            </Card>
            <Card className="info-item">
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  VISIÓN
                </Typography>
                <div className="justificado">
                  <Typography variant="body2" color="text.secondary">
                    Vemos un futuro donde la tecnología de recorridos virtuales
                    en 360° se convierte en un recurso reconocido para la compra
                    y venta de propiedades en el mercado de bienes raíces local.
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Para el 2026 seremos líderes en este campo, proporcionando
                    tanto a usuarios como clientes la mejor experiencia de
                    recorrido virtual en 360° y ofreciendo un servicio de alta
                    calidad que les brinde la confianza y tranquilidad
                    necesarias para una decisión informada.
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Siendo reconocidos como la empresa de bienes raíces más
                    innovadora y confiable del mercado, gracias a la mejora y
                    actualización constante de nuestros servicios.
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
